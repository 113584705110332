import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Inline global reset styles
const rootStyle = {
  margin: 0,
  padding: 0,
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
};

document.body.style.margin = '0';
document.body.style.padding = '0';
document.body.style.height = '100%';
document.body.style.width = '100%';

// Get the root element
const rootElement = document.getElementById('root');

// Ensure the root element exists
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <div style={rootStyle}>
      <App />
    </div>
  );
} else {
  console.error('Root element not found');
}
