import React, { useState, useEffect } from "react";
import {
  FaSitemap,
  FaDraftingCompass,
  FaNewspaper,
  FaFileAlt,
} from "react-icons/fa";

function Services() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Responsive state
  // Handle window resize for responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sectionStyle = {
    marginTop: "48px",
    // paddingBottom: "40px",
    backgroundColor: "#F8F9FA",
    position: "relative",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    // backgroundImage: `url(${isMobile ? overlayImage : "/"})`,
    // backgroundSize: "contain", // Adjust to fit the image proportionally
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: isMobile ? "center bottom" : "right bottom", // Move the image to the bottom center
  };

  const topSvgStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: "auto",
    width: "100%",
    height: "20px",
    transform: "translateZ(0)",
  };

  const containerStyle = {
    maxWidth: "1140px",
    margin: "0 auto",
    padding: "0 15px",
  };

  const cardStyle = {
    marginTop: "-30px",
    marginBottom: "20px",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
  };

  const cardHeaderImageStyle = {
    width: "100%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  };

  const cardBodyStyle = {
    padding: "20px",
    position: "relative",
    color: "black",
  };

  const svgOverlayStyle = {
    position: "absolute",
    top: "-50px",
    left: 0,
    width: "100%",
    height: "95px",
  };

  const parentContainerStyle = {
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    flexDirection: "column", // Stack the features in a column
    width: "100%",
    margin: "15px",
  };

  const featureContainer = {
    width: isMobile ? "100%" : "calc(50% - 15px)",
    // marginBottom: "20px",
    display: "flex",
    justifyContent: isMobile ? "center" : "auto",
    alignItems: isMobile ? "center" : "auto",
    margin: "10px",
  };

  const featureStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "flex-start", // Align everything to the left
    // gap: "15px", // Add spacing between icons and text
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: isMobile ? "15px" : "0px",
    width: isMobile ? "100%" : "250px",
    height: "120px",
    //flexDirection: "row", // Keep icons and text in a horizontal line
    margin: "auto",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "10px",
  };

  const featureIconStyle = {
    padding: "10px",
    // marginRight: "20px",
    marginBottom: "10px",
    backgroundColor: "#d6cda6",
    color: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    // marginRight: "15px",
    fontSize: "24px", // Icon size
  };

  const featureTextStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    // marginBottom: "5px",
  };

  const featureDescriptionStyle = {
    color: "#6c757d",
  };

  return (
    <section style={sectionStyle}>
      {/* Top SVG */}
      <div style={topSvgStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        ></svg>
      </div>

      {/* Content */}
      <div style={containerStyle}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {/* Card */}
          {/* <div style={{ width: "100%", maxWidth: "400px", padding: "15px" }}>
            <div style={cardStyle}>
              <img
                src={archImage}
                alt="Architecture"
                style={cardHeaderImageStyle}
              />
              <div style={cardBodyStyle}>
                <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Inspiracija za Vaš idealni prostor
                </h4>
                <p
                  style={{
                    marginTop: "10px",
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  Stvaramo jedinstvene arhitektonske projekte koji spajaju
                  estetiku, funkcionalnost i savremen dizajn, prilagođene vašim
                  željama i potrebama.
                </p>
              </div>
            </div>
          </div> */}

          {/* Features */}
          <div
            style={
              isMobile
                ? parentContainerStyle
                : {
                    width: "100%",
                    maxWidth: "600px",
                    padding: "15px",
                    display: "flex",
                    // flexWrap: isMobile ? "nowrap" : "wrap",
                    flexDirection: isMobile ? "column" : "row",
                    // marginLeft: isMobile ? "0px" : "80px",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            <div style={featureContainer}>
              <div style={featureStyle}>
                <div style={featureIconStyle}>
                  <FaDraftingCompass />
                </div>
                <div>
                  <div style={featureTextStyle}>Projektovanje</div>
                  {/* {!isMobile && (
                    <p style={featureDescriptionStyle}>
                      Naš tim stručnjaka nudi inovativna rješenja za
                      projektovanje, kombinirajući kreativnost i tehničku
                      preciznost.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div style={featureContainer}>
              <div style={featureStyle}>
                <div style={featureIconStyle}>
                  <FaSitemap />
                </div>
                <div>
                  <div style={featureTextStyle}>Nadzor</div>
                  {/* {!isMobile && (
                    <p style={featureDescriptionStyle}>
                      Pratimo svaki korak realizacije projekta kako bismo
                      osigurali vrhunski kvalitet i sigurnost.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div style={featureContainer}>
              <div style={featureStyle}>
                <div style={featureIconStyle}>
                  <FaNewspaper />
                </div>
                <div>
                  <div style={featureTextStyle}>Izvođenje</div>
                  {/* {!isMobile && (
                    <p style={featureDescriptionStyle}>
                      Pružamo profesionalnu izvedbu svih vrsta arhitektonskih
                      projekata, s fokusom na detalje i dugotrajnost.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div style={featureContainer}>
              <div style={featureStyle}>
                <div style={featureIconStyle}>
                  <FaFileAlt />
                </div>
                <div>
                  <div style={featureTextStyle}>Konsalting</div>
                  {/* {!isMobile && (
                    <p style={featureDescriptionStyle}>
                      Pomažemo vam da donesete najbolje odluke za dizajn,
                      materijale i tehnička rješenja, uz stručno savjetovanje.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
