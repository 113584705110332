import React, { useState, useEffect } from "react";

function Hero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const heroContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row", // Stack on mobile
    height: isMobile ? "calc(95vh - 60px)" : "calc(100vh - 60px)", // Adjust for navbar
    width: "100%",
    position: "relative",
    overflow: "hidden", // Prevent overflow
  };

  const mainTextStyle = {
    position: "absolute", // Position text in the center
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: isMobile ? "2rem" : "3rem",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "rgba(214, 205, 166, 0.4)", // Semi-transparent background
    padding: "20px",
    textAlign: "center",
    zIndex: 2, // Ensure it is on top of the video
    width: isMobile ? "90%" : "auto", // Responsive width for text
  };

  const rightSectionStyle = {
    flex: 1,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Clip overflowing content
    width: "100%",
  };

  const videoStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    pointerEvents: "none", // Disable interaction
    position: "absolute", // Prevent layout shift
  };

  return (
    <>
      <div style={heroContainerStyle}>
        {/* Overlay Text */}
        <div style={mainTextStyle}>ITArchitecture</div>

        {/* Right Section with Video */}
        <div style={rightSectionStyle}>
          <video
            src="https://ita-architecture-images.s3.eu-west-2.amazonaws.com/ani-velika/Ani_Velika.mp4"
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            controlsList="nofullscreen" // Prevents fullscreen button
            style={videoStyle}
            onLoadedData={() => setIsLoaded(true)}
          />
        </div>
      </div>
    </>
  );
}

export default Hero;
