import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Handle sticky navbar on scroll
  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const navigate = useNavigate();

  const navStyle = {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#ffffff",
    boxShadow: isSticky
      ? "0 4px 8px rgba(0, 0, 0, 0.2)" // Stronger shadow when sticky
      : "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow by default
    borderRadius: "0 0 8px 8px", // Only round the bottom corners
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
  };

  const navLinksDesktopStyle = {
    display: "flex",
    gap: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#333",
    fontSize: "16px",
    fontWeight: "500",
    padding: "8px 12px",
    borderRadius: "4px",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const linkHoverStyle = {
    backgroundColor: "#f0f0f0",
    color: "#007BFF", // Optional: highlight link on hover
  };

  const burgerStyle = {
    fontSize: "24px",
    cursor: "pointer",
    background: "none",
    border: "none",
    display: "none", // Initially hidden, shown via media queries
    color: "#333",
  };

  const mobileMenuStyle = {
    display: isMenuOpen ? "flex" : "none",
    flexDirection: "column",
    position: "absolute",
    top: "60px",
    right: 0,
    backgroundColor: "#ffffff",
    width: "100%",
    padding: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    zIndex: 999,
  };
  const logoStyle = {
    fontSize: "24px",
    color: "#333",
    cursor: "pointer",
  };

  return (
    <nav style={navStyle}>
      {/* Logo */}
      <div
        style={logoStyle}
        onClick={() => {
          if (window.location.pathname === "/") {
            // If already on the root page, scroll to the top smoothly
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            // Navigate to the root page
            window.location.href = "/";
          }
        }}
      >
        ITArchitecture
      </div>

      {/* Desktop Links */}
      <div className="nav-links-desktop" style={navLinksDesktopStyle}>
        <a href="/projects" style={{ textDecoration: "none", color: "#333" }}>
          Projekti
        </a>
        <a
  href="#"
  style={{ textDecoration: "none", color: "#333" }}
  onClick={(e) => {
    e.preventDefault(); // Prevent default link behavior
    navigate("/"); // Navigate to the root page
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 200); // Delay for ensuring navigation completes
  }}
>
  Kontakt
</a>
      </div>

      {/* Burger Menu (Mobile Only) */}
      <button
        className="burger-menu"
        style={burgerStyle}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? "✖" : "☰"}
      </button>

      {/* Mobile Links */}
      <div className="nav-links-mobile" style={mobileMenuStyle}>
        <a
          href="#home"
          style={{ textDecoration: "none", color: "#333", padding: "10px 0" }}
          onClick={() => setIsMenuOpen(false)}
        >
          Home
        </a>
        <a
          href="#projects"
          style={{ textDecoration: "none", color: "#333", padding: "10px 0" }}
          onClick={() => setIsMenuOpen(false)}
        >
          Projects
        </a>
        <a
          href="#contact"
          style={{ textDecoration: "none", color: "#333", padding: "10px 0" }}
          onClick={() => setIsMenuOpen(false)}
        >
          Contact
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
