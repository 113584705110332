import React from "react";
import { useNavigate } from "react-router-dom";
import projects from "../assets/projects/projects";

function AllProjects() {
  const navigate = useNavigate();

  const containerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
    padding: "20px",
  };

  const cardStyle = {
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  const imageStyle = {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  };

  const contentStyle = {
    padding: "15px",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "14px",
    lineHeight: "1.5",
  };

  const buttonContainerStyle = {
    textAlign: "center",
    marginBottom: "20px",
  };

  // Button Styling
  const buttonBaseStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#fff",
    border: `2px solid #d6cda6`,
    borderRadius: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#d6cda6",
    color: "#fff",
  };

  const iconStyle = {
    marginLeft: "10px",
    fontSize: "18px",
  };

  const vrTourLabel = {
    position: "absolute",
    top: 5,
    right: 5,
    padding: "10px",
    border: "1px solid transparent",
    borderRadius: "5px",
    backgroundColor: "#d6cda6",
    color: "black",
    fontWeight: "bold",
  };

  return (
    <div>
      <div style={containerStyle}>
        {projects.map((project) => (
          <div
            key={project.id}
            style={cardStyle}
            onClick={() => navigate(`/project/${project.id}`)}
          >
            <img src={project.cover} alt={project.name} style={imageStyle} />
            <div style={contentStyle}>
              <h3 style={titleStyle}>{project.name}</h3>
              {/* <p style={descriptionStyle}>{project.description}</p> */}
            </div>
            {project.hasVR && <div style={vrTourLabel}>VR Tour</div>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllProjects;
