import React from 'react';

function Footer() {
  const footerStyle = {
    backgroundColor: '#333', // Dark background for contrast
    color: '#fff', // White text for readability
    padding: '20px',
    textAlign: 'center',
    fontSize: '14px',
  };

  const contactContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
  };

  const contactItemStyle = {
    margin: 0,
  };

  const linkStyle = {
    color: '#d6cda6', // Company color for links
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const socialMediaContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    marginTop: '10px',
  };

  const socialMediaIconStyle = {
    fontSize: '18px',
    color: '#fff',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
  };

  const socialMediaIconHoverStyle = {
    color: '#d6cda6', // Change color on hover
  };

  return (
    <footer style={footerStyle}>
      {/* Company Info */}
      <div style={contactContainerStyle}>
        <h3 style={contactItemStyle}>ITArchitecture</h3>
        <p style={contactItemStyle}>
          <a href="mailto:itasmajlovic@gmail.com" style={linkStyle}>
            itasmajlovic@gmail.com
          </a>
        </p>
        <p style={contactItemStyle}>
          <a href="tel:+38761998629" style={linkStyle}>
            +38761998629
          </a>
        </p>
        <p style={contactItemStyle}>
          Ulica 21. Srebreničke brigade bb 75350 Srebrenik
        </p>
      </div>

      {/* Social Media Links */}
      {/* <div style={socialMediaContainerStyle}>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          style={socialMediaIconStyle}
          onMouseOver={(e) => Object.assign(e.target.style, socialMediaIconHoverStyle)}
          onMouseOut={(e) => Object.assign(e.target.style, socialMediaIconStyle)}
        >
          Facebook
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          style={socialMediaIconStyle}
          onMouseOver={(e) => Object.assign(e.target.style, socialMediaIconHoverStyle)}
          onMouseOut={(e) => Object.assign(e.target.style, socialMediaIconStyle)}
        >
          Instagram
        </a>
        <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          style={socialMediaIconStyle}
          onMouseOver={(e) => Object.assign(e.target.style, socialMediaIconHoverStyle)}
          onMouseOut={(e) => Object.assign(e.target.style, socialMediaIconStyle)}
        >
          Twitter
        </a>
      </div> */}

      {/* Copyright */}
      <p style={{ marginTop: '20px', fontSize: '12px' }}>
        © {new Date().getFullYear()} ITArchitecture. All rights reserved.
      </p>
      <a href="https://quicksavedev.com" style={{ marginTop: '20px', fontSize: '12px', textDecoration: 'none', color: 'inherit' }}>
  Powered by Quicksave
</a>

    </footer>
  );
}

export default Footer;
