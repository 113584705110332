const neviteraKomplexImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/0e0e8803-6d87-491f-b89b-38ad7475c0f7.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/4c231d71-fe32-4f75-95ad-79417eef3a81.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/9f37c049-0811-45fe-98f0-9f1dd6c60c07.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/14bad2dd-3944-46ff-a426-f0e2010e67c6.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/20eb5391-abaf-445e-9620-f310e77e3b9c.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/28b5da13-c123-478a-a6cc-43be9a8ddf4e.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/281a0ae3-9c06-48d4-a33b-2a1b964a086f.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/416ec400-1c44-4356-8dcd-5ba66052bbed.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/448c175d-3c8a-4437-8737-edf9f0621c62.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/456b81b8-887e-4763-bb62-bac4e2242b89.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/8953ca47-f988-4b73-84e2-5aa1070aa8d0.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/76833570-6bce-4ba2-aea2-0da0b9f00700.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/a305d7df-3a32-4467-a684-21f4cef725aa.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/abbbe9d0-3680-4824-818d-5a984e4af448.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/ca561ded-6a20-4812-83d7-c817b3325f8b.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/d0e5d314-ea19-4566-a711-7d8274143e8a.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/d04adf05-4a59-40d1-a660-81403187fc66.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/dd408b42-9349-4c4e-b5f8-9cc86f5a5b51.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/e4d33e8c-5ee4-40da-8f23-f2a2ec1854f9.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/e4e35a1d-2612-4b80-b99e-47713e3612fb.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/e809543e-1f95-453a-9a40-525e312bc771.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/f6b88e99-7363-4f42-9851-d8eb8c1e7faa.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/f814988a-ed19-42c5-8f19-e981972952ee.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/fde85ecd-8cb0-4356-8df0-80f3c948730b.jpg",
];

const neviteraKomplekxCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/nevitera-kompleks/0e0e8803-6d87-491f-b89b-38ad7475c0f7.jpg";

const stambeniSrebrenik1Images = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/d5c223b6-657a-46e2-9d41-480584601a47.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/a8231dbf-7b2a-4598-ba29-cbbab91586cf.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/dcc1da2c-bcf4-4af6-80fb-f798af2a180d.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/28f04c8f-18a3-49a0-a079-db3e147624fb.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/2376fb6f-f313-42bf-a2b9-ad0d5b40a35a.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/802924c3-2e05-4846-ac26-aa564d2e0277.jpg",
];

const stambeniSrebrenik1Cover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-1/d5c223b6-657a-46e2-9d41-480584601a47.jpg";

const trespaImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/c43e6b50-0419-49d0-9b5e-0a7a6d00280e.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/40b6a831-e7d4-4c8b-bd60-3a6eebbc04f9.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/94e6d54c-842d-4852-b46d-ebfb9a7e1c0e.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/a49b2656-9a52-4d8b-96bf-f6bf7b04b33e.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/b13d255e-727f-4017-9bb9-95213d00ffb7.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/c18eda7f-0a7c-46ff-8b02-54a683475e4f.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/27276bac-7923-4ada-9d42-c067ffd4c77c.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/d85fd411-c5bf-422d-bd46-a7ecdded9732.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/1ad86476-c96e-4e90-8295-5f8d15219cb4.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/2a73cdf0-d314-413d-99f8-5e042c50319d.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/319c4910-b8e1-494d-9c77-c3568d16bcee.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/27276bac-7923-4ada-9d42-c067ffd4c77c.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/86788f7f-5653-4034-9099-7ef8f91bd11c.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/6491704e-4a62-4630-81d8-593491efedb9.jpg",
];
const trespaCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/trespa/c43e6b50-0419-49d0-9b5e-0a7a6d00280e.jpg";

const stambeniSrebrenik_2Images = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/9be5be39-5fe7-4ed1-9fb2-fb5029f7f733.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/7cb51a5a-3fad-4434-aacf-c90357279e84.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/431cd8db-7f28-44de-a3bd-7578935134e7.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/b11eef72-e5a0-4f56-9672-51d49f82f191.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/e5e85a3e-fcd5-473b-99b1-2887b6c20ed9.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/ec051df2-b252-4ff9-940f-b9a8a1f61bad.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/002f5662-b1f1-4184-b9d1-701e499517b0.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/2c94c2ec-c566-4ecb-afa2-925fd9acf8cc.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/5fdf7ff9-e081-493b-a744-8168372a304c.jpg",
];

const stambeniSrebrenik2Cover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/stambeni-srebrenik-2/9be5be39-5fe7-4ed1-9fb2-fb5029f7f733.jpg";

const hmSrebrenikImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/efdb255b-238e-4685-b741-935c3b0671bd.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/6b2dcf76-c4e0-4306-9aca-3115355085f8.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/429a19ca-481d-4927-8075-18155c9b0e05.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/ab689fc5-c0aa-4e4e-885f-76de3d4b88a7.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/aec9cfda-15e9-49b5-87aa-199c6c68fd05.jpg",
];
const hmSrebrenikCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/hm-srebrenik/dbc5e792-0a40-4c05-b6d6-fe9660e5fb9e.jpg";

const sjedicImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/bc0b8865-48ca-4ea2-9580-be6d4cfd5b38.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/07fc10c8-0e71-47dd-bd4d-27de57f92c6a.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/7cc28355-223b-477a-938c-2c9b3e59cb06.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/9d0196c8-790e-4149-91d3-b46437a7ad91.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/8937cc05-6c9f-461c-a736-b2ed0f1daaa8.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/a1ed4df8-e418-48f7-a90c-e54685741c75.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/a53b0f4d-12e4-48ad-9426-8402692b18d2.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/a74d8c88-d4a7-45b5-a070-4645178b9dcd.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/da9d508c-ea26-498e-814b-6ebd224b912f.jpg",
];
const sjedicCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/sjedic/bc0b8865-48ca-4ea2-9580-be6d4cfd5b38.jpg";

const neum2024Images = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/1aa9a3dd-1813-4353-afc7-5bc9f8235296.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/f38e1bce-60c2-4853-b29f-ab4a9558c94a.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/ec21376a-18dc-499e-a54b-e5e3e836f2f2.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/f8b39962-84c5-451b-b6e2-bda9a3d23407.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/9ca0b9fb-cbb1-4c1c-8f27-3e3b74eaee5b.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/84a954da-4d7a-40c2-8045-2a37f88f9528.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/589d907c-2750-42b8-8192-90328efb9b45.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/1083f84a-59e8-4a66-b193-4833ba805925.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/7148d98e-8cf4-45eb-baa1-4415350b37b6.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/00813204-43f3-4450-8773-b7373be12c9a.jpg",
];
const neum2024Cover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/neum-2024/1aa9a3dd-1813-4353-afc7-5bc9f8235296.jpg";

const salkicImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/e8307aef-67d5-46c3-8de7-75578dea9c4d.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/d70540b1-4bab-42f4-b4c3-72ffa1a16821.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/cde566fa-0cdf-49e0-9a4b-90ff68a7a534.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/5d998e0f-38dc-4439-92fd-088d34a50a17.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/be59a825-bcfb-4141-8aa2-36e5e5a52728.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/31c447c6-fc09-45d5-8b2b-7fbe6d6e7beb.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/97e8a4e4-62fc-491a-89c1-869613a7a03b.jpg",
];
const salkicCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/salkic/e8307aef-67d5-46c3-8de7-75578dea9c4d.jpg";

const basicImages = [
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/81557795_2620914151297115_5296900410726940672_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/216702471_4060336787354837_8021865870240253587_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/218003541_4060336410688208_8448395128753904487_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/233563632_4138541219534393_2912693831777957479_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/233868584_4138541456201036_4463520358886940918_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/234619599_4138541759534339_7802455480582076894_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/235646122_4138541102867738_9141948095166536067_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/317964837_5574199602635207_2012409464563729883_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318002545_5574200039301830_7531477191086156137_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318063445_5574199755968525_271212904688641189_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318079397_5574199282635239_4949113945550054426_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318102611_5574199455968555_8440406520699110422_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318215688_5574200479301786_4413406810812973450_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318325034_5574198865968614_5004852101964389390_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/318447847_5574198659301968_4427776842161044567_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/81924124_2620914117963785_2680259441827250176_n.jpg",
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/82010994_2620914181297112_8222863747463512064_n.jpg",
];
const basicCover =
  "https://ita-architecture-images.s3.eu-west-2.amazonaws.com/basic/81557795_2620914151297115_5296900410726940672_n.jpg";

const projects = [
  {
    id: 1,
    name: "Nevitera Turistički Komplex Brčko",
    description:
      "Projektujemo arhitektonske komplekse koji objedinjuju estetiku, funkcionalnost i skladan raspored, stvarajući prostore prilagođene potrebama savremenih zajednica.",
    images: neviteraKomplexImages,
    hasVR: false,
    cover: neviteraKomplekxCover,
  },
  {
    id: 2,
    name: "Stambeni Objekat Srebrenik II",
    description:
      "Projektujemo stambene objekte i kuće koje spajaju moderan dizajn, funkcionalnost i toplinu doma, prilagođene vašem stilu života i potrebama porodice.",
    images: stambeniSrebrenik_2Images,
    hasVR: false,
    cover: stambeniSrebrenik2Cover,
  },
  {
    id: 3,
    name: "Trespa Stambeni Objekat",
    description:
      "Stvaramo jedinstvene arhitektonske projekte koji spajaju estetiku, funkcionalnost i savremen dizajn, prilagođene vašim željama i potrebama.",
    images: trespaImages,
    hasVR: true,
    vrLink: "https://trespa.ita-architecture.com/",
    cover: trespaCover,
  },
  {
    id: 4,
    name: "Neum Apartmani 2024",
    description:
      "Stvaramo jedinstvene arhitektonske projekte koji spajaju estetiku, funkcionalnost i savremen dizajn, prilagođene vašim željama i potrebama.",
    images: neum2024Images,
    hasVR: false,
    cover: neum2024Cover,
  },
  {
    id: 5,
    name: "Stambeni Objekat Srebrenik I",
    description:
      "Kreiramo kuće i stambene objekte koji odišu udobnošću, estetikom i praktičnošću, oblikovane prema vašim željama i svakodnevnim potrebama.",
    images: stambeniSrebrenik1Images,
    hasVR: false,
    cover: stambeniSrebrenik1Cover,
  },
  {
    id: 6,
    name: "Sjedić Stambeni Odžak",
    description:
      "Stvaramo jedinstvene arhitektonske projekte koji spajaju estetiku, funkcionalnost i savremen dizajn, prilagođene vašim željama i potrebama.",
    images: sjedicImages,
    hasVR: false,
    cover: sjedicCover,
  },
  {
    id: 7,
    name: "HM Zgrada Srebrenik",
    description:
      "Projektujemo stambene zgrade koje kombinuju savremeni dizajn, funkcionalnost i kvalitetan životni prostor, prilagođene potrebama modernih zajednica.",
    images: hmSrebrenikImages,
    hasVR: false,
    cover: hmSrebrenikCover,
  },
  {
    id: 8,
    name: "Salkić Stambeni Objekat",
    description:
      "Projektujemo stambene zgrade koje kombinuju savremeni dizajn, funkcionalnost i kvalitetan životni prostor, prilagođene potrebama modernih zajednica.",
    images: salkicImages,
    hasVR: false,
    cover: salkicCover,
  },
  {
    id: 9,
    name: "Bašić Stambeni Objekat",
    description:
      "Projektujemo stambene zgrade koje kombinuju savremeni dizajn, funkcionalnost i kvalitetan životni prostor, prilagođene potrebama modernih zajednica.",
    images: basicImages,
    hasVR: false,
    cover: basicCover,
  },
];

export default projects;
