import React, { useState } from "react";

const FullScreenVideo = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Responsive state
  const [isLoaded, setIsLoaded] = useState(false);

  const containerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
  };

  const cardStyle = {
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  const contentStyle = {
    padding: "15px",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "14px",
    lineHeight: "1.5",
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <video
          src="https://ita-architecture-images.s3.eu-west-2.amazonaws.com/gradacac-trzni/Gradacac.mp4"
          autoPlay
          style={{
            display: isLoaded ? "block" : "none",
            width: isMobile ? "100%" : "100%",
            height: isMobile ? "200px" : "400px",
            objectFit: "cover",
            pointerEvents: "none",
          }}
          loop
          muted
          playsInline
          disablePictureInPicture
          controlsList="nofullscreen" // Prevents fullscreen button from appearing
          onLoadedData={() => setIsLoaded(true)}
        />
        {!isLoaded && (
          <img src="thumbnail.jpg" alt="Loading..." style={{ width: "100%" }} />
        )}
        <div style={contentStyle}>
          <h3 style={titleStyle}>Profesionalni 3D Renderi</h3>
          <p style={descriptionStyle}>
            Specijalizujemo se za visokokvalitetne 3D rendere arhitektonskih
            objekata, uključujući stambene, poslovne i javne prostore.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FullScreenVideo;
